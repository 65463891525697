import React from "react";
import LatticeGrid from "lattice/lib/components/LatticeGrid";
import SEO from "../components/seo";

const FAQ = () => (
  <>
    <SEO title="FAQ" />
    <LatticeGrid>
      <h1>Buffy FAQ</h1>
    </LatticeGrid>
  </>
);

export default FAQ;
